import GLightbox from 'glightbox/dist/js/glightbox.js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';

function GLightboxNubee( args ) {
	let that = this;

	args = args || {};
	args.links = args.links || [];
	args.galleryParent = args.galleryParent || null;
	args.elementClass = args.elementClass || null;
	args.gLightboxOptions = args.gLightboxOptions || {};
	that.args = args;

	that.links = [];
	that.elements = [];
	that.popupOptions = {};
	that.popupInstance = {};
	that.isGallery = false;
	that.openedOnce = false;
	that.mouseTarget = null;

	that.init = function() {
		that.prepareLinks();

		if( ! that.links.length ) {
			return;
		}

		if( that.links.length > 1 ) {
			that.isGallery = true;
			that.args.elementClass = that.args.elementClass || 'lightbox-gallery-el';
		} else {
			that.args.elementClass = that.args.elementClass || 'lightbox';
		}

		that.popupOptions = {
			// En attendant qu'ils règlent leur bug qui fait que ça commence toujours au début si on utilise elements, il faut passer par classe avec l'argument selector.
			// Attention! Contrairement à ce qu'indique le nom du paramètre, c'est bien une classe (sans point) qu'il faut.
			selector: that.args.elementClass,
			// elements: that.buildElements( that.links ),
			autoplayVideos: true,
			keyboardNavigation: true,
			descPosition: 'top',
			skin: 'clean nubee',
		};

		// Override potentiel venu des paramètres
		Object.assign( that.popupOptions, that.args.gLightboxOptions );

		// Classe pour savoir si c'est une galerie ou pas
		that.popupOptions.skin = that.popupOptions.skin || 'nubee';

		if( that.isGallery ) {
			that.popupOptions.skin += ' gallery';
		} else {
			that.popupOptions.skin += ' single';
		}

		// Sinon le bodyScrollLock fait un glitch
		that.popupOptions.skin += ' scrollbar-offset-padding';

		// Si y'a un espace au bout, GLightbox capote
		that.popupOptions.skin = that.popupOptions.skin.trim();

		that.addEventListeners();
		that.popupInstance = GLightbox( that.popupOptions );
		that.addReferencesToElements();
	};

	that.prepareLinks = function() {
		that.links = [];

		// Si on a reçu un seul lien, on fait un array avec.
		if( that.args.links instanceof HTMLElement ) {
			that.args.links = [ that.args.links ];
		}

		// Ajout des liens envoyés en arguments à l'array vide
		that.links = that.links.concat(
			Array.from( that.args.links )
		);

		// Si c'est une galerie, on va chercher tous ses éléments
		if( that.args.galleryParent ) {
			that.links = that.links.concat(
				Array.from(
					that.args.galleryParent.querySelectorAll( that.args.galleryLinkSel )
				)
			);
		}
	};

	/*
	that.buildElement = function( link ) {
		let elHref = link.href || null;
		let elSource = null;
		let elType = null;
		let newElement = null;

		if( elHref ) {
			newElement = {};

			if( elHref.match(/\.(jpg|jpeg|gif|png|svg|webp|tif|tiff|bmp|apng|ico|cur|jfif|pjpeg|pjp)$/i) ) {
				elType = 'image';
			} else if( elHref.match(/\.(mp4|ogg|mov|webm|avi|mkv|ogv)$/i) ) {
				elType = 'video';
				elSource = 'local';
			} else if( elHref.match(/youtu/i) ) {
				elType = 'video';
				elSource = 'youtube'
			} else if( elHref.match(/vimeo/i) ) {
				elType = 'video';
				elSource = 'vimeo'
			} else if( elHref.match(/^#/i) ) {
				elType = 'inline';
			} else {
				elType = 'external';
			}

			newElement.href = elHref;
			newElement.type = elType;

			if( elSource ) {
				newElement.source = elSource;
			}
		}

		return newElement;
	};


	that.buildElements = function( links ) {
		links.forEach( function( thisLink ) {
			that.elements.push( that.buildElement( thisLink ) );
		} );

		return that.elements;
	};
	*/

	that.addEventListeners = function() {
		// Enregistrement d'éventuelles fonctions personnalisées ajoutées dans les paramètres
		let customOnOpen = that.popupOptions.onOpen || null;
		let customOnClose = that.popupOptions.onClose || null;

		that.popupOptions.onOpen = function() {
			let popupBlock = that.popupInstance.modal || null;
			disableBodyScroll( popupBlock, { reserveScrollBarGap: true } );

			// Fermeture si on clique en-dehors de l'image
			if( ! that.openedOnce ) {
				if( popupBlock ) {
					// On enregistre la cible, pour différentier un vrai clic d'un drag
					popupBlock.addEventListener( 'mousedown', function( event ) {
						that.mouseTarget = event.target;
					} );

					popupBlock.addEventListener( 'click', function( event ) {
						if(
							that.mouseTarget
							&& that.mouseTarget == event.target
							&& event.target.matches( '.ginner-container' )
						) {
							that.popupInstance.close();
						}

						that.mouseTarget = null;
					} );
				}

				that.popupInstance.openedOnce = true;
			}

			if( customOnOpen ) {
				customOnOpen();
			}
		};

		that.popupOptions.onClose = function() {
			let popupBlock = that.popupInstance.modal || null;
			enableBodyScroll( popupBlock, { reserveScrollBarGap: true } );

			if( customOnClose ) {
				customOnClose();
			}
		};
	};

	// Permet par la suite d'accéder au popup à partir de n'importe quel élément <a> ou du parent de la galerie
	that.addReferencesToElements = function() {
		let elements = that.links;
		
		if( that.args.galleryParent ) {
			elements.push( that.args.galleryParent );
		}

		elements.forEach( function( thisLink ) {
			thisLink._GLightboxNubee = that;
			thisLink._GLightbox = that.popupInstance;
		} );
	};

	that.init();
}

export default GLightboxNubee;