function PrettyFileInput( args ) {
	let that = this;

	args = args || {};
	args.input = args.input || null;
	args.parentSel = args.parentSel || '.wrap-field';
	args.wrapperSel = args.wrapperSel || '.pretty-file-input';
	args.labelSel = args.labelSel || '.file-input-label';
	args.wrapLabelSel = args.wrapLabelSel || '.wrap-file-input-label';
	args.wrapLabelInnerSel = args.wrapLabelInnerSel || '.wrap-file-input-label-inner';

	args.labelSeveralFilesClass = args.labelSeveralFilesClass || 'label-several-files';
	args.labelOneFileClass = args.labelOneFileClass || 'label-one-file';
	args.labelNoFilesClass = args.labelNoFilesClass || 'label-no-files';
	args.numberClass = args.numberClass || 'number';
	args.dragSupportedClass = args.dragSupportedClass || 'supports-drag';
	args.draggingClass = args.draggingClass || 'dragging';
	args.draggingThisClass = args.draggingThisClass || 'dragging';
	args.dragOverlayClass = args.dragOverlayClass || 'drag-overlay';
	args.dragOverlayInnerClass = args.dragOverlayInnerClass || 'drag-overlay-inner';

	args.deleteButtonIconClasses = args.deleteButtonIconClasses || [ 'icon', 'icon-close-x-thick' ];
	args.deleteButtonClass = args.deleteButtonClass || 'file-input-delete';

	args.debug = args.debug || false;

	that.args = args;

	that.init = function() {
		that.i18n = window.bonesGlobals.i18n.prettyFileInput;
		that.elementsDragging = 0; // Nombre d'éléments au-dessus desquels on est en train de drag dans tout le site
		that.elementsDraggingThis = 0; // Nombre d'éléments au-dessus desquels on est en train de drag dans ce champ

		that.input = that.args.input;
		that.parent = that.input.closest( that.args.parentSel );
		that.wrapper = that.parent.querySelector( that.args.wrapperSel );

		that.initLabels();
		that.initDeleteButton();

		that.dragSupported = that.checkDragSupport();

		if( that.args.debug && ! that.i18n ) {
			console.error( "pretty-file-input: i18n missing. Make sure you put all required entries in 'bonesGlobals.i18n.prettyFileInput'." );
			return;
		}

		if( ! that.input || ! that.parent || ! that.wrapper || ! that.wrapLabel || ! that.labels.noFiles ) {
			return;
		}

		that.initDrag();

		that.applyFileNumber();
		that.addEventListeners();
	};

	that.checkDragSupport = function() {
		return (
			'draggable' in that.wrapper 
			|| (
				'ondragstart' in that.wrapper
				&& 'ondrag' in that.wrapper
			)
		);
	};

	// On crée les éléments de labels qui disent si on a des fichiers et combien.
	that.initLabels = function() {
		that.wrapLabel = that.parent.querySelector( that.args.wrapLabelSel );
		that.wrapLabelInner = that.parent.querySelector( that.args.wrapLabelInnerSel );
		that.labels = {};

		// Enregistrement du label (pas de fichiers)
		that.basicLabelText = that.i18n.simple;
		that.labels.noFiles = that.parent.querySelector( that.args.labelSel );

		// Copie et différentiation d'un nouveau label (un seul fichier)
		that.labels.oneFile = that.labels.noFiles.cloneNode();
		that.labels.oneFile.classList.add( args.labelOneFileClass );
		that.wrapLabelInner.append( that.labels.oneFile );

		// Copie et différentiation d'un nouveau label (avec fichiers)
		that.labels.severalFiles = that.labels.noFiles.cloneNode();
		that.labels.severalFiles.innerHTML = that.i18n.hasFiles.replace( '%s', '<span class="' + that.args.numberClass + '">' + 0 + '</span>' );
		that.labels.severalFiles.classList.add( that.args.labelSeveralFilesClass );
		that.wrapLabelInner.append( that.labels.severalFiles );

		// Ajout d'une référence pour aller modifier le span du nombre et mettre le chiffre dedans
		that.labels.number = that.labels.severalFiles.querySelector( '.' + that.args.numberClass );

		// Ajout d'une classe pour différencier le label (pas de fichiers)
		that.labels.noFiles.classList.add( that.args.labelNoFilesClass );
	};

	// On ajoute un bouton X qui permet de vider le champ file si on s'est trompé
	that.initDeleteButton = function() {
		that.deleteButton = document.createElement( 'button' );
		that.deleteButton.type = 'button';
		that.deleteButton.title = that.i18n.deleteFiles;
		that.deleteButton.ariaLabel = that.i18n.deleteFiles;
		that.deleteButton.classList.add( that.args.deleteButtonClass );

		// Ajout d'une icône
		let icon = document.createElement( 'span' );
		icon.classList.add( ...that.args.deleteButtonIconClasses );
		icon.role = 'presentation';
		that.deleteButton.append( icon );

		// Ajout du X au wrap des labels
		that.wrapLabelInner.append( that.deleteButton );
	};

	// On prépare tout ce qui a trait au drag-and-drop
	that.initDrag = function() {
		if( that.dragSupported ) {
			let dragOverlay = document.createElement( 'span' );
			let dragOverlayInner = document.createElement( 'span' );

			dragOverlay.classList.add( that.args.dragOverlayClass );
			dragOverlayInner.classList.add( that.args.dragOverlayInnerClass );
			dragOverlayInner.innerHTML = that.i18n.dropHere;

			dragOverlay.append( dragOverlayInner );
			that.dragOverlay = dragOverlay;
			that.wrapper.append( that.dragOverlay );

			that.basicLabelText = that.i18n.simpleDrag;

			that.wrapper.classList.add( that.args.dragSupportedClass );
		} else {
			that.dragOverlay = null;
			that.wrapper.classList.remove( that.args.dragSupportedClass );
		}

		that.labels.noFiles.innerHTML = that.basicLabelText;
	};

	that.applyFileNumber = function() {
		let fileNumber = that.input.files.length || 0;

		if( 1 == fileNumber ) {
			// normalize permet d'inverser la division du e et de son accent aigu qui se produit normalement.
			that.labels.oneFile.innerHTML = that.input.files[0].name.normalize();
		}

		that.wrapper.setAttribute( 'data-number-files', fileNumber );
		that.labels.number.innerHTML = fileNumber;
	};

	that.updateDragClasses = function() {
		if( that.elementsDragging > 0 ) {
			document.documentElement.classList.add( that.args.draggingClass );
		} else {
			document.documentElement.classList.remove( that.args.draggingClass );
		}

		if( that.elementsDraggingThis > 0 ) {
			that.wrapper.classList.add( that.args.draggingThisClass );
		} else {
			that.wrapper.classList.remove( that.args.draggingThisClass );
		}
	};

	that.incrementElementsDragging = function( multiplier = 1 ) {
		that.elementsDragging = Math.max( ( that.elementsDragging + multiplier ), 0 );
	};

	that.incrementElementsDraggingThis = function( multiplier = 1 ) {
		that.elementsDraggingThis = Math.max( ( that.elementsDraggingThis + multiplier ), 0 );
	};

	that.triggerChange = function( target ) {
		// On informe l'input qu'il a changé, parce qu'il est visiblement pas au courant.
		if( that.args.debug ) console.log('pretty-file-input: triggered change');
		let changeEvent = new Event( 'change' );
		target.dispatchEvent( changeEvent );
	};

	that.addEventListeners = function() {
		// Si le form est validé, on veut pas faire la modification dès le changement. La validation va nous le dire quand elle va avoir fini.
		let changeEventName;

		if( that.input.form._FormValidation ) {
			changeEventName = 'validationcomplete';
		} else {
			changeEventName = 'change';
		}

		that.input.addEventListener( changeEventName, function() {
			if( that.args.debug ) console.log('pretty-file-input: Field changed. Applying number.');
			that.applyFileNumber();
			that.deleteButton.disabled = false;
		} );

		// Classes qui nous permettent de faire apparaître l'overlay dès qu'on est en train de dragger un fichier dans le site
		document.addEventListener( 'dragenter', function() {
			that.incrementElementsDragging();
			that.updateDragClasses();
		} );

		document.addEventListener( 'dragleave', function() {
			that.incrementElementsDragging( -1 );
			that.updateDragClasses();
		} );

		// Classes pour que le champ devienne plus présent quand on est en train de dragger directement dessus
		that.wrapper.addEventListener( 'dragenter', function() {
			that.incrementElementsDraggingThis();
			that.updateDragClasses();
		} );

		that.wrapper.addEventListener( 'dragleave', function() {
			that.incrementElementsDraggingThis( -1 );
			that.updateDragClasses();
		} );


		// Si on annule pas ces évènements-là, l'évènement drop ne se lancera pas
		let eventsToPrevent = [ 'dragover', 'dragenter' ];

		eventsToPrevent.forEach( function( eventName ) {
			that.wrapper.addEventListener( eventName, function( event ) {
				event.preventDefault();
			} );
		} );


		// On met le fichier dans l'input
		that.wrapper.addEventListener( 'drop', function( event ) {
			// Sinon la page va juste ouvrir le document glissé et on va perdre le site
			event.preventDefault();

			that.elementsDragging = 0;
			that.elementsDraggingThis = 0;
			that.updateDragClasses();

			if( event.dataTransfer.files ) {
				that.input.files = event.dataTransfer.files;
				that.triggerChange( that.input );

				if( that.args.debug ) {
					console.log( '[DROP] set files to :' );
					console.log( that.input.files );
				}
			}
		} );

		// Bouton delete : vider le input file
		that.deleteButton.addEventListener( 'click', function( event ) {
			// Sinon cliquer sur le delete ouvrirait Parcourir
			event.stopPropagation();
			that.deleteButton.disabled = true;
			that.input.value = '';
			that.triggerChange( that.input );

			if( that.args.debug ) console.log( "pretty-file-input: button pressed; deleted files." );
		} );
	};

	that.init();

	if( ! that.label ) {
		if( that.args.debug ) {
			console.error( "pretty-file-input: One or more of the following elements was not found." );
			console.log( "input:" );
			console.log( that.input );
			console.log( "parent '" + that.args.parentSel + "':" );
			console.log( that.parent );
			console.log( "wrapper '" + that.args.wrapperSel + "':" );
			console.log( that.wrapper );
			console.log( "wrapLabel : '" + that.args.wrapLabelSel + "':" );
			console.log( that.wrapLabel );
			console.log( "labels.noFiles : '" + that.args.labelSel + "':" );
			console.log( that.labels.noFiles );
		}

		return [];
	} else {
		that.input._PrettyFileInput = that;
	}
}

export default PrettyFileInput;