import ScrollClassAgent from 'scroll-class-agent.js';
import SmoothScroller from 'smooth-scroller.js';

// Les fonctions sont à la fin du fichier

export function setGlobalVariables() {
	/*** Taille des éléments fixed **************************************************************************/

	calcFixedEls();

	[ 'load', 'scrollclasseschanged' ].forEach( function( eventName ) {
		window.addEventListener( eventName, function() {
			calcFixedEls();
		} );
	} );


	/*** Dimensions de la fenêtre ***************************************************************************/

	calcWindowDimensions();

	window.addEventListener( 'resize', function() {
		calcWindowDimensions();
	} );


	/*** Niveau de scroll ***********************************************************************************/

	calcCurScroll( true );

	window.addEventListener( 'hashblocked', function() {
		calcCurScroll( true );
	} );

	window.addEventListener( 'scrollintervalbefore', function() {
		calcCurScroll();
	} );

	// lastScroll mis à jour après tous les évènements scrollinterval
	window.addEventListener( 'scrollintervalafter', function() {
		window.lastScroll = window.curScroll;
	} );

	// Quand on resize, le lastScroll se fait resetter tout de suite, sinon on aurait de drôles de glitchs
	window.addEventListener( 'resize', function() {
		calcCurScroll();
		window.lastScroll = window.curScroll;
	} );


	/*** Classes du body qui se mettent à jour à chaque intervalle de scroll ********************************/

	window.scrollClassAgent = new ScrollClassAgent({
		classes: [
			{
				class: 'scrolling-up',
				scrollChange: 'negative',
			},
			{
				class: 'scrolling-down',
				scrollChange: 'positive',
			},
			{
				class: 'scrolled-a-bit',
				minimum: 30,
			},
			{
				class: 'scrolled-a-lot',
				minimum: ( window.vHeight / 2 ),
			},
		],
	});

	window.addEventListener( 'scrollinterval', function() {
		window.scrollClassAgent.updateClasses();
	} );


	/*** Variables CSS ***********************************************************************************/

	// Largeur de la scrollbar
	window.scrollbarWidth = getScrollbarWidth();
	document.documentElement.style.setProperty( '--scrollbar-width', window.scrollbarWidth + "px" );

	// Hauteur du header
	// (Dans calcFixedEls)

	// Taille initiale du viewport
	// Fait semblant que les scrollbars existent pas pour compatibilité avec les vw/vh en CSS.
	window.vwInitial = window.innerWidth * 0.01;
	document.documentElement.style.setProperty( '--vw-initial', window.vwInitial + "px" );

	window.vhInitial = window.innerHeight * 0.01;
	document.documentElement.style.setProperty( '--vh-initial', window.vhInitial + "px" );


	/*** Autres variables globales **************************************************************************/

	// Éviter des erreurs si jamais Google Analytics est pas là ou a une vieille version.
	window.ga = window.ga || window._gaq || null;

	// Le smoothScroller global
	window.smoothScroller = new SmoothScroller({
		parent: window,
		// debug: true,
	});
}


// Retourne la largeur en pixels de la scrollbar du site.
// https://stackoverflow.com/a/13382873/1995518
function getScrollbarWidth() {
	// Creating invisible container
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.position = 'absolute';
	outer.style.overflowY = 'scroll'; // forcing scrollbar to appear
	outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
	outer.style.width = '100%';
	document.body.appendChild(outer);

	// Creating inner element and placing it in the container
	const inner = document.createElement('div');
	outer.appendChild(inner);

	// Calculating difference between container's full width and the child width
	const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

	// Removing temporary elements from the DOM
	outer.parentNode.removeChild(outer);

	return scrollbarWidth;
}


// Met à jour la largeur/hauteur de l'écran
export function calcWindowDimensions() {
	window.vWidth = document.documentElement.clientWidth;
	window.vHeight = document.documentElement.clientHeight;

	window.vWidthExceptFixed = document.documentElement.clientWidth - window.fixedElWidth;
	window.vHeightExceptFixed = window.vHeight - window.fixedElHeight;
}


// Calcule la hauteur totale des éléments fixed. Cette valeur va servir à tasser le scroll des ancres pour pas que le contenu visé soit derrière le header.
export function calcFixedEls() {
	let pageMenuEl = document.querySelector('.page-menu-block');
	let adminBarEl = document.querySelector('#wpadminbar');
	let siteHeaderEl = document.querySelector('.site-header');

	let newFixedElWidth;
	let newFixedElHeight;

	newFixedElWidth = 0;
	newFixedElHeight = pageMenuEl ? pageMenuEl.offsetHeight : 0;
	newFixedElHeight += adminBarEl ? adminBarEl.offsetHeight : 0;

	window.headerHeight = siteHeaderEl.offsetHeight;
	document.documentElement.style.setProperty( '--header-height', window.headerHeight + "px" );

	window.headerHeight = siteHeaderEl.offsetHeight;

	newFixedElHeight += window.headerHeight;

	if( window.fixedElWidth != newFixedElWidth ) {
		let changedEvent = new Event( 'fixedelementwidthchanged' );
		window.fixedElWidth = newFixedElWidth;
		window.dispatchEvent( changedEvent );
	}

	if( window.fixedElHeight != newFixedElHeight ) {
		let changedEvent = new Event( 'fixedelementheightchanged' );
		window.fixedElHeight = newFixedElHeight;
		window.dispatchEvent( changedEvent );
	}
}


// Détermine en fonction du niveau de scroll où on en est et où est le milieu de l'écran
export function calcCurScroll( forceLastScroll = false ) {
	window.curScroll = {
		top: Math.floor( document.documentElement.scrollTop || document.body.scrollTop || 0 ),
		left: Math.floor( document.documentElement.scrollLeft || document.body.scrollLeft || 0 ),
		topExceptFixed: Math.floor( ( document.documentElement.scrollTop || document.body.scrollTop || 0 ) + window.fixedElHeight ),
		leftExceptFixed: Math.floor( ( document.documentElement.scrollLeft || document.body.scrollLeft || 0 ) + window.fixedElWidth ),
	};

	window.vCenter = {
		x: window.curScroll.left + ( window.vWidth / 2 ),
		y: window.curScroll.top + ( window.vHeight / 2 ),
		xExceptFixed: window.curScroll.left + ( window.vWidthExceptFixed / 2 ),
		yExceptFixed: window.curScroll.top + ( window.vHeightExceptFixed / 2 ),
	};

	if( forceLastScroll ) {
		window.lastScroll = window.curScroll;
	}
}