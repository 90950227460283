import { tns } from 'tiny-slider/src/tiny-slider.module.js';

function initSliderTiny( slider, extraOptions ) {
	let lazySelector = '.tns-lazy-img';
	let autoplayButtonSelector = '.tns-autoplay-button';

	if( 'string' == typeof slider ) {
		slider = document.querySelector( slider );
	}
	let sliderOptions = {};
	let sliderObject; // eslint-disable-line no-unused-vars
	let maxItems = parseFloat( slider.dataset.maxItems || 1 );
	let minItems = parseFloat( slider.dataset.minItems || 1 );
	let maxWidth = parseFloat( slider.dataset.maxWidth || Infinity );
	let minWidth = parseFloat( slider.dataset.minWidth || 0 );
	let nbItems = slider.querySelectorAll('.slider-element').length;
	
	extraOptions = extraOptions || {};

	if( nbItems > 1 && window.vWidth > minWidth && window.vWidth < maxWidth ) {
		sliderOptions = {
			container: slider,
			mode: slider.classList.contains( 'fade' ) ? 'gallery' : 'carousel',
			axis: slider.classList.contains( 'vertical' ) ? 'vertical' : 'horizontal',
			autoplay: slider.classList.contains( 'autoplay' ),
			items: minItems, // les settings responsive grandissent ce nombre
			gutter: slider.classList.contains( 'gutter' ) ? 20 : 0, // les settings responsive grandissent ce nombre
			edgePadding: slider.classList.contains( 'edge-padding' ) ? 20 : 0,
			fixedWidth: false,
			autoWidth: false,
			viewportMax: false,
			slideBy: 1,
			center: false,
			controls: slider.classList.contains( 'controls' ),
			controlsPosition: slider.classList.contains( 'controls-top' ) ? 'top' : 'bottom',
			controlsText: [
				'<span class="button-inner"><span class="icon icon-chevron-thin-left" aria-label="' + window.bonesGlobals.i18n.tinySlider.prev + '" title="' + window.bonesGlobals.i18n.tinySlider.prev + '"></span></span>',
				'<span class="button-inner"><span class="icon icon-chevron-thin-right" aria-label="' + window.bonesGlobals.i18n.tinySlider.next + '" title="' + window.bonesGlobals.i18n.tinySlider.next + '"></span></span>',
			],
			// va falloir étudier comment ça marche pour les boutons custom
			controlsContainer: slider.dataset.navContainer,
			nav: slider.classList.contains( 'nav' ),
			navPosition: slider.classList.contains( 'nav-top' ) ? 'top' : 'bottom',
			navContainer: slider.dataset.navContainer,
			navAsThumbnails: slider.classList.contains( 'nav-thumbs' ),
			arrowKeys: slider.classList.contains( 'arrow-keys' ),
			speed: slider.dataset.speed || 300,
			autoplayPosition: 'top',
			autoplayTimeout: slider.dataset.autoplayTimeout || 5000,
			autoplayDirection: slider.classList.contains( 'autoplay-backward' ) ? 'backward' : 'forward',
			autoplayText: [
				window.bonesGlobals.i18n.tinySlider.autoplay.start,
				window.bonesGlobals.i18n.tinySlider.autoplay.stop,
			],
			autoplayHoverPause: slider.classList.contains( 'autoplay-hover-pause'),
			autoplayButton: slider.querySelector( autoplayButtonSelector ) || false,
			autoplayButtonOutput: true,
			autoplayResetOnVisibility: true,
			loop: slider.classList.contains( 'loop' ),
			rewind: slider.classList.contains( 'rewind' ),
			autoHeight: slider.classList.contains( 'auto-height' ),
			lazyload: slider.querySelectorAll( lazySelector ).length > 0,
			lazyloadSelector: lazySelector,
			touch: slider.classList.contains( 'touch' ),
			mouseDrag: slider.classList.contains( 'drag' ),
			preventScrollOnTouch: slider.classList.contains( 'prevent-scroll' ),

			// Voir plus bas pour le responsive
			responsive: {}
			// onInit: function() {}
		};

		// Placeholders pour pouvoir mettre des valeurs responsive plus bas

		sliderOptions.responsive['500'] = {};
		sliderOptions.responsive['700'] = {};
		sliderOptions.responsive['1000'] = {};
		sliderOptions.responsive['1200'] = {};
		sliderOptions.responsive['1400'] = {};
		sliderOptions.responsive['1600'] = {};


		// Options responsive

		if( slider.classList.contains( 'edge-padding' ) ) {
			sliderOptions.responsive['500'].edgePadding = 40;
			sliderOptions.responsive['700'].edgePadding = 50;
			sliderOptions.responsive['1000'].edgePadding = 80;
			sliderOptions.responsive['1400'].edgePadding = 120;
			sliderOptions.responsive['1600'].edgePadding = 0;
		}

		if( slider.classList.contains( 'gutter' ) ) {
			sliderOptions.responsive['500'].gutter = 30;
		}

		if( maxItems >= 2 ) {
			sliderOptions.responsive['700'].singleItem = false;
			sliderOptions.responsive['700'].items = 2;

			if( maxItems >= 3 ) {
				sliderOptions.responsive['1200'].items = 3;
			}

			if( maxItems >= 4 ) {
				sliderOptions.responsive['1600'].items = 4;
			}
		}

		// Options custom qui écrasent tout le reste

		if( Object.keys( extraOptions ).length > 0 ) {
			for( let optionName in extraOptions ) {
				sliderOptions[optionName] = extraOptions[optionName];
			}
		}

		// Initialisation

		// console.log( sliderOptions );
		sliderObject = tns( sliderOptions );
		slider.classList.add('slider-initiated');
	} else {
		slider.querySelectorAll( lazySelector ).forEach( function( thisEl ) {
			thisEl.classList.add('lazy');
		} );
	}
}

export default initSliderTiny;